<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>

    <div class="flex flex-col">
      <div class="-my-2 sm:-mx-6 lg:-mx-8">
        <nav
          class="
                flex
                mb-8
                justify-between
                sm:px-6
                lg:px-8
                flex-wrap
                gap-y-4
                lg:h-10
                md:h-10
              "
          aria-label="Breadcrumb"
        >
          <ol class="flex items-center space-x-4">
            <li>
              <div>
                <a
                  href="https://staging.ajsolicitors.com/"
                  class="text-gray-400 hover:text-gray-500"
                >
                  <HomeIcon
                    class="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span class="sr-only">Home</span>
                  </a>
              </div>
            </li>
            <li
              v-for="page in pages"
              :key="page.name"
            >
              <div class="flex items-center">
                <ChevronRightIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <a
                  :href="page.href"
                  class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  :aria-current="page.current ? 'page' : undefined"
                >{{ page.name }}</a>
              </div>
              </li>
          </ol>

          </nav>
          <div class="
          py-2
          align-middle
          inline-block
          min-w-full
          sm:px-6
          lg:px-8
          max-w-full
        ">
            <div
              v-if="emails?.items && emails.items.length > 0"
              class="
            shadow
            border-b border-gray-200
            sm:rounded-tl-lg sm:rounded-tr-lg
            overflow-auto
          "
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                    >
                      NAME & EMAIL
                    </th>
                    <th
                      scope="col"
                      class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                    >
                      TYPE
                      </th>
                      <th
                        scope="col"
                        class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                      >
                        PROPERT PRICE
                        </th>
                        <th
                          scope="col"
                          class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                        >
                          SDLT PREVIEW PRICE
                          </th>
                          <th
                            scope="col"
                            class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                          >
                            SUBJECT
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              TEXT
                          </th>
                          <!-- <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            Legal Fee
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            Land Registry Fee
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            Land Registry Searches
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            Search Packs
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            VAT
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            Mortgage Fee
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            ID verification Fee
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            Electronic Transfer fee
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            Archive Fee
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            Verification of Lawyer Fee
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            Stamp Duty Land Tax Fee
                          </th>
                          <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                            Stamp Duty
                          </th> -->
                       

                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    :class="i % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    v-for="(item, i) in emails.items"
                    :key="item.id"
                  >
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div>
                          <div
                            :title="item.client_name"
                            class="
                          text-sm
                          font-medium
                          text-gray-900
                          lg:truncate
                          md:truncate
                          max-w-xs
                        "
                          >
                            {{ item.client_name }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ item.client_email }}
                        </div>
                      </div>
          </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">

            <div class="">
              <div class="text-sm font-medium text-gray-900">
                {{item.type_of_sale}}
              </div>

            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">

            <div class="">
              <div class="text-sm font-medium text-gray-900">
                {{item.property_price}}
              </div>

            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">

            <div class="">
              <div class="text-sm font-medium text-gray-900">
                {{item.preview_price}}
              </div>

            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">

            <div class="">
              <div class="text-sm font-medium text-gray-900">
                {{item.subject}}
              </div>

            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">

            <div class="">
              <div class="text-sm font-medium text-gray-900">
                {{item.text}}
              </div>

            </div>
          </td>
          </tr>
          </tbody>
          </table>
      </div>
      <Pagination
        @page-changed="handlePageChanged"
        :total="emails.count"
        :current_page="current_page"
      />
    </div>
  </div>
  </div>
  </div>
</template>

<script>
import { ChevronRightIcon, HomeIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { DateTime } from "luxon";
const pages = [{ name: "Sent quotes", href: "#", current: true }];

import { ref, computed, onMounted } from "vue";
import Pagination from "@/components/Pagination";
// import Avatar from "@/components/Avatar";
import { getAppTypes } from "../../../services/case";

export default {
  components: {
    // Avatar,
    ChevronRightIcon,
    HomeIcon,
    Pagination
  },
  setup() {
    const store = useStore();
    const types = ref("");

    onMounted(async () => {
      try {
        const { data } = await getAppTypes();
        types.value = data;
      } catch (e) {
        console.log(e);
      }
    });

    store.dispatch("cases/getEmails");

    const open = ref(false);
    const current_page = ref(1);

    console.log("email sned");
    //send email
    const handlePageChanged = async page => {
      await store.dispatch("cases/getEmails", page);
      current_page.value = page;
    };
    return {
      open,
      pages,
      DateTime,
      current_page,
      emails: computed(() => store.state.cases.emails),
      types,
      handlePageChanged
    };
  }
};
</script>